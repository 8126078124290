import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import {
  PageWrapper,
  Container,
  Inner,
  DemoWrapper,
  VideoWrapper,
  VideoText,
} from '../components/Elements';
import HomeBlock from '../components/HomeBlock';
import { colors, gradients } from '../consts/style';
import Video from '../components/Video';
import SEO from '../components/SEO';
import mq from '../style/mq';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import Section1 from '../components/Section1';
import BlogListing from '../components/BlogListing';
import AppBlock from '../components/AppBlock';

const GradientWrapper = styled.div`
  ${gradients.default};
  padding: 8rem 0;
  ${mq.tabletSmall`
    padding: 4rem 0;
  `}
`;

const HeroWrapper = styled.div`
  min-height: 200px;
  background: ${colors.blue};
  #vimeo-embed > div {
    border: none;
    padding-top: calc(56.25% + 200px) !important;
    ${mq.tabletSmall`
      padding-top: 400px !important;
    `}
  }
`;

const indexQuery = graphql`
  {
    home: datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      section1Gif {
        url
      }
      videoTitle
      videoId
      heroVideoId
      section1Text
      appSectionTitle
      appSectionBody
      appSectionImage {
        fluid(maxWidth: 800, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      appStoreUrl
      googlePlayStoreUrl
      featuresTitle
      features {
        id
        title
        body
        icon {
          fluid(maxWidth: 800, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      blogsTitle
      blogPosts {
        id
        title
        slug
        shortDescription
        featuredIn
        featuredImage {
          fluid(maxWidth: 500, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      pricingTitle
    }
    drilling: datoCmsDrillingPage {
      heroGif {
        url
      }
      homepageBlockHeading
      homepageBlockBody
    }
    exploration: datoCmsExplorationPage {
      heroGif {
        url
      }
      homepageBlockHeading
      homepageBlockBody
    }
    misc: datoCmsMisc {
      pricing {
        id
        quantity
        cost
        body
      }
    }
  }
`;

export default function IndexPage() {
  const data = useStaticQuery(indexQuery);
  const {
    seoMetaTags,
    features,
    section1Gif,
    section1Text,
    featuresTitle,
    pricingTitle,
    videoTitle,
    videoId,
    heroVideoId,
    blogPosts,
    blogsTitle,
    appSectionTitle,
    appSectionBody,
    appSectionImage,
    appStoreUrl,
    googlePlayStoreUrl,
  } = data.home;
  const { drilling, exploration, misc } = data;

  const section1Props = {
    gif: section1Gif.url,
    text: section1Text,
  };

  const drillingProps = {
    bg: colors.tealDriller,
    color: colors.dark,
    heading: drilling.homepageBlockHeading,
    body: drilling.homepageBlockBody,
    slug: '/for-drillers/',
    gif: drilling.heroGif.url,
    inverted: false,
    buttonTextColor: colors.tealDriller,
  };

  const explorationProps = {
    bg: colors.grey,
    color: 'white',
    heading: exploration.homepageBlockHeading,
    body: exploration.homepageBlockBody,
    slug: '/for-explorers/',
    gif: exploration.heroGif.url,
    inverted: true,
    buttonTextColor: 'white',
  };

  const videoProps = {
    id: videoId,
  };

  const featureProps = {
    features,
    title: featuresTitle,
  };
  const pricingProps = {
    pricing: misc.pricing,
    title: pricingTitle,
  };

  const heroVideoProps = {
    id: heroVideoId,
  };

  const blogProps = {
    blogPosts,
    blogsTitle,
  };

  const appSectionProps = {
    appSectionTitle,
    appSectionBody,
    appSectionImage,
    appStoreUrl,
    googlePlayStoreUrl,
  };

  return (
    <PageWrapper>
      <SEO meta={seoMetaTags} />
      <HeroWrapper>
        <Video {...heroVideoProps} />
      </HeroWrapper>
      <div id="intro" />
      <Section1 {...section1Props} />
      <AppBlock {...appSectionProps} />
      <HomeBlock {...drillingProps} />
      <HomeBlock {...explorationProps} />
      <DemoWrapper>
        <Container>
          <Inner>
            <VideoWrapper>
              <Video {...videoProps} />
            </VideoWrapper>
            <VideoText>
              <h2>{videoTitle}</h2>
            </VideoText>
          </Inner>
        </Container>
      </DemoWrapper>
      <GradientWrapper>
        <Features {...featureProps} />
        <BlogListing {...blogProps} />
       {/* <Pricing {...pricingProps} /> */}
      </GradientWrapper>
    </PageWrapper>
  );
}
