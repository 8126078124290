/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styled from 'styled-components';

import { videoTypes } from '../types/propTypes';

const Wrapper = styled.div`

`;

export default function Video({ id }) {

  const trimmedId = id.toString().trim();
  const fallBackId = '385608531'; // The "Drill with Lantern" video at https://vimeo.com/385608531
  const finalId = trimmedId ?? fallBackId;

  return (
    <Wrapper>
     <div id={'vimeo-embed'} dangerouslySetInnerHTML={{__html: `<div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/${finalId}?badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style="position:absolute;top:0;left:0;width:100%;height:100%;">
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>`}}/>
    </Wrapper>
  );
}

Video.propTypes = videoTypes;
